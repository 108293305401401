 /*eslint-disable import/no-anonymous-default-export*/
export default {
    CREATE_VENDOR: () => `/admin/vendors/addVendor `,
    UPDATE_VENDOR: (id) => `/admin/vendors/updateVendor/${id}`,
    UPDATE_DRIVER: (id) => `/admin/drivers/updateDriver/${id}`,
    TOGGLE_VENDOR_CAR: (id) => `/admin/vendorCars/update/${id}`,
    GET_VENDOR_BY_ID:(id)=>`/admin/vendors/getVendorById/${id}`,
    GET_DRIVER_BY_ID:(id)=>`/admin/drivers/getDriverById/${id}`,
    GET_VENDOR: () => `/admin/vendors/getAllVendor`,
    GET_VENDOR_CAR: (id) => `/admin/vendorCars/getVendorCars/${id}`,
    GET_DRIVER: () => `/admin/drivers/getAllDrivers`,
  };
  