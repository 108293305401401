
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect, useMemo } from 'react';
import services from '../../services';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import constant from "../../constant";
function    VendorsList() {
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const imageBaseURL = constant.IMAGE_URL;

    const [columnDefs, ] = useState([
        
        { field: 'id',headerName: "Id", filter: true },
        { field: 'firstName',headerName: "First Name", filter: true },
        { field: 'lastName',headerName: "Last Name", filter: true },
        { field: 'phoneNumber',headerName: 'Phone Number', filter: true },
        { field: 'email',headerName: 'Email', filter: true },
        { field: 'VendorDetail', headerName: 'Alternate Number',filter: true,cellRenderer: (params) => {return(params.data.VendorDetail.alternativePhoneNumber)} },
        { field: 'VendorDetail', headerName: 'Aadhar Linked Number',filter: true,cellRenderer: (params) => {return(params.data.VendorDetail.aadharLinkedPhoneNumber)} },
        { field: 'VendorDetail', headerName: 'Aadhar Number',filter: true,cellRenderer: (params) => {return(params.data.VendorDetail.aadharNumber)}  },
        { field: 'VendorDetail', headerName: 'Firm Registration',filter: true,cellRenderer: (params) => {return(params.data.VendorDetail.registerationNumber)}  },
        { field: 'VendorDetail', headerName: 'Oneway Commission',filter: true,cellRenderer: (params) => {return(params.data.VendorDetail.oneWayCommission)}  },
        { field: 'VendorDetail', headerName: 'Twoway Commission',filter: true,cellRenderer: (params) => {return(params.data.VendorDetail.twoWayCommission)}  },
        { field: 'VendorDetail', headerName: 'Sharing Commission',filter: true,cellRenderer: (params) => {return(params.data.VendorDetail.sharingCommission)}  },
        { field: 'VendorDetail', headerName: 'Approval Status',filter: true,cellRenderer: (params) => {return(<div className={`badge bg-${params.data.VendorDetail.approvalStatus == 'approved' ? 'success' : 'primary'}`}>{params.data.VendorDetail.approvalStatus}</div>)}  },
        {
            field: 'action', filter: false,
            cellRenderer: (params) => {
                return (<>
             
               
                    <Link to={`/editvendor/${params.data.id}`}>
                    <i className={`bx bx-edit bx-xs`} style={{ marginRight: '8px', cursor: 'pointer' }}></i>
                </Link>
                 <Link to={`/vendorcar/${params.data.id}`}>
                 <i className={`bx bx-car bx-xs`} style={{ marginRight: '8px', cursor: 'pointer' }}></i>
             </Link></>
                );
            }
        },
    ]);  
   
    const handleToggle =async (id,isActive,key) => {
     const data={}
     data[key]=isActive
     
    const response=  await  services.Vendor.UPDATE_VENDOR(id,data)
    if(response){
        getVendorListHandler()
    }

      };


    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
        width: 170,
    }),[]);


    const getVendorListHandler = async () => {
        try {
            const response = await services.Vendor.GET_VENDOR()
    
            setRowData(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVendorListHandler()
    }, []);




    return (
        <div className="App">

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <SideBar />
                    <div className="layout-page">
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="d-flex py-3 align-items-center justify-content-between">
                                    <h4 className="fw-bold ">Vendor List</h4>
                                    <Link className="btn btn-primary" to={'/addvendor'}>Add Vendor</Link>
                                </div>
                                <div className="ag-theme-alpine"    style={{ width: "100%", height: "90%" }}>
                                    <AgGridReact
                                        ref={gridRef} // Ref for accessing Grid's API

                                        rowData={rowData} // Row Data for Rows


                                        columnDefs={columnDefs} // Column Defs for Columns
                                        defaultColDef={defaultColDef} // Default Column Properties

                                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                                        rowSelection='multiple' // Options - allows click selection of rows
                                    />
                                </div>
                            </div>
                            <footer className="content-footer footer bg-footer-theme">
                                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                                    <div className="mb-2 mb-md-0">
                                        ©
                                       Cars53
                                    </div>
                                </div>
                            </footer>
                            <div className="content-backdrop fade"></div>
                        </div>
                    </div>
                </div>
                <div className="layout-overlay layout-menu-toggle"></div>
            </div>
        </div>
    );
}

export default VendorsList;
